
import { useMeta } from "vue-meta";
import { defineComponent, ref } from "vue";
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;

export default defineComponent({
  emits: [...useDialogPluginComponent.emits],
  setup() {
    const {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    } = useDialogPluginComponent();
    const food = ref("");
    useMeta({
      title: "I nostri prodotti",
      description: `Per informazioni, disponibilità e prenotazioni scrivere a
        luciaingiuseppe@gmail.com o contattateci al +39 0875 911044`,
    });

    return {
      dialogRef,
      onDialogHide,
      food,

      showDialog(val: string) {
        food.value = val;
        dialogRef.value?.show();
      },
      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick() {
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK();
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel,
    };
  },
});

<template>
  <div>
    <q-img
      alt="prodotti"
      height="400px"
      src="https://images.unsplash.com/photo-1537640538966-79f369143f8f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1053&q=80"
    />

    <div class="flex justify-center container">
      <h4 v-if="$q.platform.is.mobile">I nostri prodotti</h4>
      <h3 v-else>I nostri prodotti</h3>
      <p
        :class="[
          'text-subtitle1 d-none',
          $q.platform.is.mobile ? 'q-px-md' : '',
        ]"
      >
        Questa casa molisana nasce da un sogno, rendere Grande la Semplicità. La
        passione e la fantasia di creare dei piatti che racchiudono sapori e
        odori tipici del basso molise, cibi nutrienti e genuini che madre terra
        sa regalarci, succedere con sentimento una tradizione culinaria
        tramandata da genitori a figli, la voglia incondizionata di continuare a
        gestire un'attività agricola nonostante l'enorme peso del periodo
        storico nel quale ci troviamo. Sfidare fenomeni sociali come
        l'urbanizzazione di terreni fertili di agricoltura, lo spopolamento
        sempre più vivo dei nostri territori con l'obiettivo di valorizzare il
        nostro patrimonio locale e culturale.
      </p>
    </div>

    <div class="row q-pb-xl q-col-gutter-xl">
      <div class="col-12 col-md-6">
        <q-card @click="showDialog('olio')" flat class="cursor-pointer">
          <q-img
            alt="prodotti"
            height="500px"
            fit="contain"
            src="https://mercato-italiano.nl/wp-content/uploads/2020/05/OM4351_1.png"
          >
          </q-img>

          <q-card-section class="text-h6 text-center">
            Olio extra vergine di oliva biologico
          </q-card-section>
        </q-card>
      </div>

      <div class="col-12 col-md-6">
        <q-card @click="showDialog('vino')" flat class="cursor-pointer">
          <q-img
            alt="prodotti"
            height="500px"
            fit="contain"
            src="https://imagenpng.com/wp-content/uploads/2015/04/Imagenes-Vector-botellas-de-vino-png-7.png"
          >
          </q-img>

          <q-card-section class="text-h6 text-center">
            Il vino IGT campagne molisane
          </q-card-section>
        </q-card>
      </div>

      <div
        class="container text-center flex justify-center q-mt-xl"
        style="max-width: 800px"
      >
        Per informazioni, disponibilità e prenotazioni scrivere a
        luciaingiuseppe@gmail.com o contattateci al +39 0875 911044
      </div>
    </div>

    <!-- Olio -->
    <q-dialog
      ref="dialogRef"
      :maximized="$q.platform.is.mobile"
      @hide="onDialogHide"
    >
      <q-card flat class="cursor-pointer">
        <q-card-section class="flex justify-end">
          <q-icon @click="onOKClick" style="font-size: 2em;" name="close" />
        </q-card-section>
        <q-img
          alt="prodotti"
          height="400px"
          fit="contain"
          :src="
            food === 'olio'
              ? 'https://mercato-italiano.nl/wp-content/uploads/2020/05/OM4351_1.png'
              : 'https://imagenpng.com/wp-content/uploads/2015/04/Imagenes-Vector-botellas-de-vino-png-7.png'
          "
        >
        </q-img>

        <q-card-section>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </q-card-section>

        <q-card-actions align="right">
          <q-btn color="primary" label="Chiudi" @click="onOKClick" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts">
import { useMeta } from "vue-meta";
import { defineComponent, ref } from "vue";
import { useDialogPluginComponent } from "quasar";

export default defineComponent({
  emits: [...useDialogPluginComponent.emits],
  setup() {
    const {
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    } = useDialogPluginComponent();
    const food = ref("");
    useMeta({
      title: "I nostri prodotti",
      description: `Per informazioni, disponibilità e prenotazioni scrivere a
        luciaingiuseppe@gmail.com o contattateci al +39 0875 911044`,
    });

    return {
      dialogRef,
      onDialogHide,
      food,

      showDialog(val: string) {
        food.value = val;
        dialogRef.value?.show();
      },
      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick() {
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK();
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel,
    };
  },
});
</script>
